/**
 * GTMについて、以下リンクのGoogleTagManager設定例と違い`_app.tsx`に`routeChangeComplete`のeventを設定していない。
 * 理由はGoogleTagManagerの「GA4」タグにて「Nextjs用のページ遷移取得」トリガーが設定されており、gtm側が自分でページ遷移を検知するため。
 * see: https://nextjs.org/docs/basic-features/script
 * see: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''

export const pageview = (url: string) => {
  if (!GTM_ID) return

  window.dataLayer.push({
    event: 'pageview',
    page: url,
  })
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}
